import CheckoutLayout from '@/components/Layout/CheckoutLayout';
import CityNoFooterLayout from '@/components/Layout/CityNoFooterLayout';
import DefaultLayout from '@/components/Layout/DefaultLayout';
import NoFooterLayout from '@/components/Layout/NoFooterLayout';
import PrivateLayout from '@/components/Layout/PrivateLayout';
import DefaultLayout2 from '@/components2/Layout/DefaultLayout';
import DefaultLayout3 from '@/componentsWhitelabel/Layout/DefaultLayout3';
import PartnerLayout from '@/componentsPartner/Layout/PartnerLayout';
import LandingPageLayout from '@/components/Layout/LandingPageLayout';
import { getStores, StoreProvider } from '@stores/index';
import * as gtagmodule from '@utils/gtag';
import { TRACKER_ENABLED } from '@utils/tracker_enabled';
import Router, { useRouter } from 'next/router';
import Script from 'next/script';
import NProgress from 'nprogress'; //nprogress module
import 'nprogress/nprogress.css'; //styles of nprogress
import { useCallback, useEffect, useState } from 'react';
require('@/styles/styles.less');
import '../utils/i18n';

declare const window: any;

const layouts = {
  DefaultLayout,
  DefaultLayout2,
  CheckoutLayout,
  PrivateLayout,
  NoFooterLayout,
  CityNoFooterLayout,
  PartnerLayout,
  LandingPageLayout,
  DefaultLayout3,
};

const isProduction = process.env.NODE_ENV === 'production' && TRACKER_ENABLED;

function FacebookPixel() {
  useEffect(() => {
    import('react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        window.ReactPixel = ReactPixel;
        ReactPixel.init('522092471979398');
        ReactPixel.pageView();
        Router.events.on('routeChangeComplete', (url) => {
          ReactPixel.pageView();
          // if (url.indexOf('Confirmed') > 0) {
          //   const split = url.split('/');
          //   ReactPixel.track('Purchase', { code: split[split.length - 1] });
          // }
        });
      });
  });
  return null;
}

// if (isProduction) ReactGA.initialize('UA-106538319-1');

export default function MyApp({ Component, pageProps }) {
  const stores = getStores();
  const router = useRouter();

  const clearPaymentLoading = useCallback(() => {
    stores?.reservationStore?.setPaymentLoading(false);
  }, [stores?.reservationStore]);

  // Use the layout defined at the page level, if available
  let Layout = layouts[Component.layout] || layouts.DefaultLayout;
  // console.log('PageProp keys', Layout, Object.keys(pageProps));
  if (Component.check_ab == 1 && pageProps.ab == 1) {
    Layout = layouts['DefaultLayout2'];
  }

  useEffect(() => {
    router.events.on('routeChangeStart', () => {
      NProgress.start();
    });

    // if (isProduction) ReactGA.pageview(window.location.pathname + window.location.search);
    const handleRouteChange = (url) => {
      clearPaymentLoading();
      NProgress.done();
      gtagmodule.pageview(url);
      gtagmodule.parseUrlData(router);
      window.uetq = window.uetq || [];
      window.uetq.push('event', 'page_view', { page_path: url });
      //      if (isProduction) ReactGA.pageview(url)
    };
    //When the component is mounted, subscribe to router changes
    //and log those page views
    router.events.on('routeChangeComplete', handleRouteChange);

    gtagmodule.parseUrlData(router);
    // If the component is unmounted, unsubscribe
    // from the event with the `off` method

    Router.events.on('routeChangeError', () => NProgress.done());

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [clearPaymentLoading, router.events]);

  return (
    <StoreProvider value={stores}>
      <Layout airports={pageProps?.airports}>
        <>
          {/* Google Optimize */}
          {/* <Script
            strategy="beforeInteractive"
            src={`https://www.googleoptimize.com/optimize.js?id=${gtagmodule.OPTIMIZE_ID}`}
          ></Script> */}

          {/* Api Google */}
          {/* <Script
            strategy="beforeInteractive"
            src="https://apis.google.com/js/api.js"
          /> */}
          <Script
            strategy="beforeInteractive"
            src="https://accounts.google.com/gsi/client"
          />
          {isProduction && (
            <>
              {/** Cookie bot app
              {!isServer && window.location.hostname.includes('app') ? (
                <Script
                  strategy="afterInteractive"
                  id="cookieyes"
                  type="text/javascript"
                  src="https://cdn-cookieyes.com/client_data/b42b7597f14ea28fcd3ecee1/script.js"
                />
              ) : (
                <Script
                  strategy="beforeInteractive"
                  id="cookieyes"
                  type="text/javascript"
                  src="https://cdn-cookieyes.com/client_data/84122ac95971fc0701d14cdc.js"
                />
              )}
 */}
              <Script id="gtm" strategy="afterInteractive">
                {`
                  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
                  new Date().getTime(),event:'gtm.js',ab:sessionStorage.getItem('ab') ? sessionStorage.getItem('ab') : 1});var f=d.getElementsByTagName(s)[0],
                  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
                  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
                  })(window,document,'script','dataLayer','${gtagmodule.GTM_ID}');
                    function getOriginalLocation() {
                      var savedLocation = sessionStorage.getItem('originalLocation');
                      var newLocation = document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search;

                      var isPageReloaded = null;
                      try {
                        if (window.performance) {
                          if (performance.navigation.type == 1) {
                            isPageReloaded = 1
                          }
                          else {
                            isPageReloaded = 0
                          }
                        }
                      }
                      catch (e) {}
                      if (!savedLocation || isPageReloaded === 0) {
                        sessionStorage.setItem('originalLocation', newLocation);
                      }
                      var location = isPageReloaded && savedLocation ? savedLocation : newLocation;
                      return location;
                    }
                    window.dataLayer = window.dataLayer || [];
                    window.dataLayer.push({
                      originalLocation: getOriginalLocation()
                    });
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${gtagmodule.GTM_ID}', {
                      page_path: window.location.pathname + window.location.search,
                    });
                    try {
                      let data = [];
                      data = JSON.parse(sessionStorage.getItem('synthetic'));
                      if (!data) data = [];
                      data.push([new Date().toISOString(), 'ld', window.location.pathname + window.location.search]);
                      sessionStorage.setItem('synthetic', JSON.stringify(data));
                    } catch (e) {
                     // console.log(e)
                    }
                    try {
                      if (localStorage.getItem('randomId')==null) {
                        localStorage.setItem('randomId', Math.floor(Math.random()*30000));
                      }
                    } catch (e) {
                    }
                    
                `}
              </Script>

              {/* Global tiktok */}
              <Script id="ads" strategy="afterInteractive">
                {`
                !function (w, d, t) {
                  w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
            )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
                
                  ttq.load('CH4HME3C77U2I5R8IF40');
                  ttq.page();
                }(window, document, 'ttq');
                `}
              </Script>
              {/* Clicky */}
              {/* <Script
                async
                data-id={gtagmodule.CLICKY_ID}
                src="//static.getclicky.com/js"
              ></Script> */}

              {/* Global site tag (gtag.js) - Google Analytics - Analytics */}

              {/* Global site tag (gtag.js) - Google Ads: 925699234 */}
              <Script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${gtagmodule.AW_ID}`}
              ></Script>
              <Script id="ads" strategy="afterInteractive">
                {`
                  window.dataLayer = window.dataLayer || []; 
                  function gtag() { dataLayer.push(arguments); } 
                  gtag("js", new Date()); 
                  gtag("config", "${gtagmodule.AW_ID}");
                `}
              </Script>

              {/* Global site tag (gtag.js) - Google Analytics - Analytics */}
              {/* <Script
                async
                src={`https://www.googletagmanager.com/gtag/js?id=${gtagmodule.UA_ID}`}
              ></Script>
              <Script id="google-ua" strategy="afterInteractive">
                {`
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){dataLayer.push(arguments);}
                  gtag('js', new Date());
 
                  gtag('config', '${gtagmodule.UA_ID}');
                `}
              </Script> */}

              {/* Bing */}
              <Script id="bing" strategy="afterInteractive">
                {`
                  (function(w,d,t,r,u){var f,n,i;w[u]=w[u]||[],f=function(){var o={ti:"17504303"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");
                `}
              </Script>
            </>
          )}
        </>

        <Component {...pageProps} />
        {isProduction && <FacebookPixel />}
      </Layout>
    </StoreProvider>
  );
}
